import React from 'react';
import { useElementList } from '../../hooks/useElementList';
import EventsPriceItem from './EventsPriceItem';

const EventsPrice = ({ eventPricesRails }) => {

    const initElements = [
        {
            price: '',
            name: ''
        }
    ];
    
    const { elementList, addElement, removeElementByIndex, changeElementList } = useElementList(
        eventPricesRails !== null && eventPricesRails.length > 0
            ? eventPricesRails 
            : initElements
    )

    const handleAddPrice = (e) => {
        e.preventDefault();

        addElement({
            price: '',
            name: ''
        })
    }

    return (
        <div className="form-group">
            <label className="form-label">Precios del evento</label>
            <div className="event-price-area">
                <div className="row">
                    <div className="col">
                        <label>Precio</label>
                    </div>
                    <div className="col">
                        <label>Descripción</label>
                    </div>
                </div>
                {
                    elementList.map((element, index) => (
                        <EventsPriceItem
                            key={ index }
                            event={ element }
                            index={ index }
                            changeElementList={ changeElementList }
                            removeElementByIndex={ removeElementByIndex }
                            isRemoveEnable={ 
                                elementList.length === 1 ? false : true
                            }
                        />
                    ))
                }

                <input type="hidden" name="event[prices]" value={ JSON.stringify(elementList) } />
                <button
                    className="btn btn-primary rounded rounded-circle"
                    onClick={ handleAddPrice }
                >
                    +
                </button>
            </div>
        </div>
    )
}

export default EventsPrice;
